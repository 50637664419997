import { useCountdown } from './useCountdown';

export default function CountDownTimerMobile({ targetDate }) {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <div></div>;
    } else {
        return (
            <div className='countdown'>
                <div className='timer'>
                    <div className='value'>
                        {days < 10 ? '0' + days : days}
                    </div>
                    <div className='value-label'>
                        Days
                    </div>
                </div>
                <div className='value-spacer'>:</div>
                <div className='timer'>
                    <div className='value'>
                        {hours < 10 ? '0' + hours : hours}
                    </div>
                    <div className='value-label'>
                        Hours
                    </div>
                </div>
                <div className='value-spacer'>:</div>

                <div className='timer'>
                    <div className='value'>
                        {minutes < 10 ? '0' + minutes : minutes}
                    </div>
                    <div className='value-label'>
                        Minutes
                    </div>
                </div>
                <div className='value-spacer'>:</div>

                <div className='timer'>
                    <div className='value'>
                        {seconds < 10 ? '0' + seconds : seconds}

                    </div>
                    <div className='value-label'>
                        Seconds
                    </div>
                </div>
            </div>
        );
    }
};
