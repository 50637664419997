
const axios = require('axios');

export default function CheckBox(props) {


    const getPinata = (number) => {

        const name = (num) => {
            if (num < 101) {
                if (num > 11) {
                    return "0" + (num - 1)
                } else {
                    return "00" + (num - 1)
                }
            } else {
                return num - 1
            }
        }
        const url = `https://api.pinata.cloud/data/pinList?metadata[name]=${name(number)}.png`;


        return axios
            .get(url, {
                headers: {
                    pinata_api_key: 'db258c67ef6c112e6d99',
                    pinata_secret_api_key: '1a7f55e2ef11a8676f3987e96009e5bb76d7159d809c1e894431234a65d003e2'
                }
            })
            .then(function (response) {
                //handle response here
                if (!props.checked) {
                    if (props.gooseurls.length < 6) {
                        props.setSelectedGeese(props.selectedGeese.concat(props.i));
                        props.setGooseUrls(props.gooseurls.concat(response.data.rows[response.data.rows.length - 1].ipfs_pin_hash));
                    } else {
                        alert('max goose limit reached');
                    }
                } else {
                    props.setSelectedGeese(props.selectedGeese.filter(function (goose) { return goose !== props.i }));
                    props.setGooseUrls(props.gooseurls.filter(function (goose) { return goose !== response.data.rows[response.data.rows.length - 1].ipfs_pin_hash }));
                }
            })
            .catch(function (error) {
                //handle error here
                alert('this goose does not have a corresponding image. Please contact creator')
                console.log(error)
            });
    }

    return (
        <div className='goose' >
            <input
                type='checkbox'
                className='goose-check'
                checked={props.checked}
                onChange={() => { getPinata(Number(props.i.replace('CGF #', ''))) }}
            />
            <div>
                {props.i}
            </div>
            {/* spacers */}
            <div></div>
            <div></div>
        </div>
    )
}