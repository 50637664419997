import './MobileHeader.css';
import logo from '../../assets/Logo.svg';
import walletIcon from '../../assets/wallet.svg';
import burgerIcon from '../../assets/menu_icon.svg';
import closeIcon from '../../assets/close_icon.svg';
import dashboardIcon from '../../assets/Dashboard_Icon.svg';
import raffleIcon from '../../assets/Raffle_Icon.svg';
import marketIcon from '../../assets/Missions_Icon.svg';
import honkIcon from '../../assets/Honk_Icon.svg';
import ConnectWalletBox from '../ConnectWalletBox/ConnectWalletBox';
import lock from '../../assets/Lock_Icon.svg';
import { useState } from 'react';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export default function MobileHeader(props) {
    const [isMenu, setMenu] = useState(false);
    const [isConnectBox, setConnectBox] = useState(false);


    return (

        <div className="mobile-header">
            <img className='mobile-logo' src={logo} alt="" />
            <div className='menu-trigger'>
                <div className='user-icon-mobile'
                    style={{
                        backgroundImage: props.cgfs > 0 ?
                            `url(https://raw.githubusercontent.com/VentralScarab/Crazy-Goose-Flock/main/Images/JPEG/${props.cgfs}.jpeg)`
                            :
                            `url(${walletIcon})`
                        ,
                        backgroundSize: props.cgfs ? '150%' : '80%',
                        backgroundPositionX: props.cgfs ? '55%' : '50%',
                        backgroundPositionY: props.cgfs ? '0%' : '40%',
                        backgroundRepeat: props.cgfs ? '' : 'no-repeat'
                    }}
                >
                </div>
                <div>&nbsp;&nbsp;&nbsp;</div>
                <img className='burger' src={burgerIcon} alt="=" onClick={() => setMenu(!isMenu)} />
            </div>
            <div
                className='slideout-bar'
                style={{
                    right: isMenu ? '0%' : '-100%',
                    // display: isMenu ? '' : 'none'
                }}
            >
                <div className='close-button' onClick={() => setMenu(false)}>
                    <img className="x" src={closeIcon} alt="x" />
                </div>
                {/* MENU CONTENT */}
                {/* CONNECT */}
                <div className='mobile-connect-wrapper'>
                    <div className='user-icon-mobile'
                        style={{
                            backgroundImage: props.cgfs > 0 ?
                                `url(https://raw.githubusercontent.com/VentralScarab/Crazy-Goose-Flock/main/Images/JPEG/${props.cgfs}.jpeg)`
                                :
                                `url(${walletIcon})`
                            ,
                            backgroundSize: props.cgfs ? '150%' : '80%',
                            backgroundPositionX: props.cgfs ? '55%' : '50%',
                            backgroundPositionY: props.cgfs ? '0%' : '40%',
                            backgroundRepeat: props.cgfs ? '' : 'no-repeat'
                        }}
                    >
                    </div>
                    &nbsp;&nbsp;
                    <button className='mobile-menu-btn' onClick={() => setConnectBox(!isConnectBox)}>
                        {
                            props.selectedWallet ?
                                props.selectedWallet.slice(0, 6) + '...' + props.selectedWallet.substr(props.selectedWallet.length - 6)
                                :
                                'CONNECT WALLET'
                        }

                    </button>
                    {props.setSelectedWallet && props.gootBalance > 0 ?
                        <span className='mobile-balance'>${numberWithCommas(props.gootBalance)}.00 Goots</span>
                        :
                        null
                    }
                </div>
                <div className='mobile-nav'>
                    {
                        isConnectBox ?
                            <ConnectWalletBox
                                isConnectBox={isConnectBox}
                                setConnectBox={setConnectBox}
                                setGootBalance={props.setGootBalance}
                                setSelectedWallet={props.setSelectedWallet}
                                selectedWallet={props.selectedWallet}
                                setWalletType={props.setWalletType}
                                walletType={props.walletType}
                                connector={props.connector}
                                myAlgoWallet={props.myAlgoWallet}
                                cgfs={props.cgfs}
                                setCgfs={props.setCgfs}
                                algodClient={props.algodClient}


                            />
                            :
                            null
                    }
                    <div
                        className='mobile-selection'
                        style={{ background: props.page === 'dashboard' ? '#dd67ff' : 'none' }}
                        onClick={() => props.setPage('dashboard')}
                    >
                        <img className='mobile-selection-icon' src={dashboardIcon} alt="" />
                        &nbsp;Dashboard &nbsp;
                    </div>
                    <div
                        className='mobile-selection'
                        style={{ background: props.page === 'raffle' ? '#dd67ff' : 'none' }}
                        onClick={() => props.setPage('raffle')}
                    >
                        <img className='mobile-selection-icon' src={raffleIcon} alt="" />
                        &nbsp;Raffle &nbsp;
                    </div>
                    <div
                        className='mobile-selection'
                        style={{ background: props.page === 'missions' ? '#dd67ff' : 'none' }}
                    onClick={() => props.setPage('missions')}
                    >
                        <img className='mobile-selection-icon' src={marketIcon} alt="" />
                        &nbsp;Missions&nbsp;
                    </div>
                    <div
                        className='mobile-selection'
                        style={{ background: props.page === 'honk' ? '#dd67ff' : 'none' }}
                        onClick={() => props.setPage('honk')}
                    >
                        <img className='mobile-selection-icon' src={honkIcon} alt="" />
                        &nbsp;Honk &nbsp;
                    </div>
                </div>
                <div className='socials'>
                    <div className='social-link' style={{ fontSize: '3vw', color: '#cdcdcc' }}>
                        Socials
                    </div>
                    <div className='social-link'>
                        <a href='https://twitter.com/CrazyGooseFlock' >Twitter</a>
                    </div>
                    <div className='social-link'>
                        <a href='https://discord.gg/zqq96srMY8 '>Discord</a>

                    </div>
                    <div className='social-link'>
                        <a href='https://www.nftexplorer.app/collection/crazy-goose-flock'>NFTx</a>

                    </div>
                    <div className='social-link'>
                        <a href='https://www.randgallery.com/collection/crazy-goose-flock/'>Rand Gallery</a>
                    </div>
                </div>
            </div>

        </div >
    )
}