import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


export default function Drag(props) {

    const [testarr, setTestArr] = useState(
        props.gooseurls

    );
    const stagger = {
        6: ['50%', '30%', '15%', '-15%', '-30%', '-50%'],
        // 7: ['100%', '65%', '35%', '0%', '-35%', '-65%', '-100%'],
        // 8: ['145%', '103%', '60%', '20%', '-20%', '-60%', '-103%', '-145%'],
        // 9: ['190%', '141%', '92%', '47%', '0%', '-47%', '-92%', '-141%', '-190%'],
    }

    useEffect(() => {
        setTestArr(props.gooseurls)
    }, [props.gooseurls])

    // const half = Math.floor(testarr.length / 2);

    // const firstHalf = testarr.slice(0, half)
    // const secondHalf = testarr.slice(-half);

    // const margin = (arr) => {
    //     var mid = arr.length / 2;                     // mid point with decimal if odd
    //     var lngt = parseInt(mid);                       // mid point without decimal
    //     var strt = 10;                                    // start increment
    //     var rr = [], ll = [];                           // create empty arrays for both sides
    //     for (var i = 0; i < lngt; i++) {                      // loop 
    //         rr.push(-strt);                             // push to both arrays
    //         ll.push(strt)
    //         strt += Math.round((strt / 50) * 50);          // increment strt for its own value + some percentage
    //     }
    //     ll.reverse();                                 // reverse left array so it is mirror of right
    //     if (mid % 1 !== 0) { ll.push(0); }                  // push extra element (0) on the end of left array if testarr length is odd

    //     var percentArray = ll.concat(rr);               // concat both arrays 
    //     return percentArray;

    // }

    // let marginArr = margin(testarr)


    // useEffect(() => {
    //     console.log(marginArr)
    // });


    const getListStyle = (isDraggingOver, itemsLength) => ({
        background: isDraggingOver ? props.color : "transparent",
        // border: '1px solid blue',
        // position: 'absolute',
        display: "flex",
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            testarr,
            result.source.index,
            result.destination.index
        );
        setTestArr(items)
    }

    return (
        <div
            style={{
                // border: '1px solid green',
                position: 'absolute',
                width: `100%`,
                bottom: '0%',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    // border: '1px solid green',
                    position: 'absolute',
                    width: `calc(20% *${testarr.length} `,
                    bottom: '0%',
                }}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver, testarr.length)}
                                {...provided.droppableProps}
                            >
                                {testarr.map((item, index) => {


                                    return (
                                        <Draggable key={`dragId${index}`} draggableId={`dragId${index}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <img
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            marginLeft: testarr.length <= 5 ? '0%' : stagger[testarr.length][index]
                                                        }}
                                                        alt=""
                                                        src={`https://gateway.pinata.cloud/ipfs/${item}`}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                }

                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div >
        </div >
    );
}

