
import './DesktopHeader.css'
import logo from '../../assets/Logo.svg';
import dashboardIcon from '../../assets/Dashboard_Icon.svg';
import raffleIcon from '../../assets/Raffle_Icon.svg';
import marketIcon from '../../assets/Missions_Icon.svg';
import honkIcon from '../../assets/Honk_Icon.svg';
import walletIcon from '../../assets/wallet.svg';
import ConnectWalletBox from '../ConnectWalletBox/ConnectWalletBox';
import { useEffect, useState } from 'react';
import lock from '../../assets/Lock_Icon.svg'

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function DesktopHeader(props) {

    const [isConnectBox, setConnectBox] = useState(false);



    return (
        <div className="header">
            <img className='logo' src={logo} alt=""
                onClick={() => {
                    if (props.selectedWallet === props.Ventral || props.selectedWallet === props.Deepnothing) {
                        props.setPage('admin')
                    } else {
                        props.setPage('dashboard')
                    }
                }}
            />
            <div
                className='nav-button nav-active'
                style={{ background: props.page === 'dashboard' ? '#dd67ff' : '' }}
                onClick={() => props.setPage('dashboard')}
            >
                <img src={dashboardIcon} alt="" className='nav-icon' />
                <span>Dashboard</span>
            </div>
            <div
                className='nav-button  nav-active'
                style={{ background: props.page === 'raffle' ? '#dd67ff' : '' }}
                onClick={() => props.setPage('raffle')}
            >
                <img src={raffleIcon} alt="" className='nav-icon' />
                <span>Raffle</span>
            </div>
            <div
                className='nav-button  nav-active'
                style={{ background: props.page === 'missions' ? '#dd67ff' : '' }}
                onClick={() => {
                    props.setPage('missions')
                }}
            >
                <img src={marketIcon} alt="" className='nav-icon' />
                <span>Missions</span>
            </div>
            <div
                className='nav-button  nav-active'
                style={{ background: props.page === 'honk' ? '#dd67ff' : '' }}
                onClick={() => props.setPage('honk')}
            >
                <img src={honkIcon} alt="" className='nav-icon' />
                <span>Honk</span>
            </div>

            <div className='button-wrapper'>
                <div className='user-icon'
                    style={{
                        backgroundImage: props.cgfs > 0 ?
                            `url(https://raw.githubusercontent.com/VentralScarab/Crazy-Goose-Flock/main/Images/JPEG/${props.cgfs}.jpeg)`
                            :
                            `url(${walletIcon})`
                        ,
                        backgroundSize: props.cgfs ? '150%' : '80%',
                        backgroundPositionX: props.cgfs ? '55%' : '50%',
                        backgroundPositionY: props.cgfs ? '0%' : '40%',
                        backgroundRepeat: props.cgfs ? '' : 'no-repeat'

                    }}
                />

                <div className='goots-wrapper'>
                    <button className='connect-btn' onClick={() => setConnectBox(!isConnectBox)}>
                        {
                            props.selectedWallet ?
                                props.selectedWallet.slice(0, 6) + '...' + props.selectedWallet.substr(props.selectedWallet.length - 6)
                                :
                                'CONNECT WALLET'
                        }

                    </button>
                    {props.setSelectedWallet && props.gootBalance > 0 ?
                        <span className='goots'>${numberWithCommas(props.gootBalance)}.00 $CRUMB</span>
                        :
                        null
                    }
                </div>
            </div>

            {
                isConnectBox ?
                    <ConnectWalletBox
                        isConnectBox={isConnectBox}
                        setConnectBox={setConnectBox}
                        setGootBalance={props.setGootBalance}
                        setSelectedWallet={props.setSelectedWallet}
                        selectedWallet={props.selectedWallet}
                        setWalletType={props.setWalletType}
                        walletType={props.walletType}
                        connector={props.connector}
                        myAlgoWallet={props.myAlgoWallet}
                        cgfs={props.cgfs}
                        setCgfs={props.setCgfs}
                        algodClient={props.algodClient}


                    />
                    :
                    null
            }


        </div>
    )
}