import './App.css';
import Header from './components/Header/Header';
import Dashboard from './components/Dashboard/Dashboard';
import './assets/Montserrat-Regular.ttf';
import "./assets/Montserrat-Bold.ttf";
import {useEffect, useState} from 'react';
import Footer from './components/Footer/Footer';
import Honk from './components/Honk/Honk';
import Raffle from './components/Raffle/Raffle';
import MyAlgoWallet from '@randlabs/myalgo-connect';
import RaffleAdmin from './components/RaffleAdmin/RaffleAdmin';
import Banner from './components/BannerBuilder/Banner';
import algosdk from 'algosdk';
import Missions from './components/Missions/Missions';
import Rewards from "./components/Rewards/Rewards";


function App() {

    const [page, setPage] = useState('dashboard');
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [gootBalance, setGootBalance] = useState(null);
    const [walletType, setWalletType] = useState(null);
    const [cgfs, setCgfs] = useState(null)
    const myAlgoWallet = new MyAlgoWallet();
    const algodClient = new algosdk.Algodv2('', 'https://mainnet-api.algonode.cloud', '');
    const gootsAssetID = 751294723;


    // useEffect(() => {
    //   if (localStorage.getItem('selectedWallet') && localStorage.getItem('walletType') === 'myalgo') {
    //       setWalletType(localStorage.getItem('walletType'))
    //       setSelectedWallet(localStorage.getItem('selectedWallet'))
    //       algodClient.accountInformation(localStorage.getItem('selectedWallet')).do()
    //           .then((res) =>
    //               res.assets.map((i) => {
    //                 if (i["asset-id"] === 751294723) {
    //                   setGootBalance(i.amount)
    //                 }
    //               })
    //           );
    //       const wallet = localStorage.getItem('selectedWallet')
    //       const toNum = (value) => {
    //         return Number(value.replace('CGF #', ''))
    //       }
    //       if (!wallet) return;
    //       algodClient.accountInformation(wallet).do().then(resmain => {
    //         const IDS = resmain.assets.reduce(function (filtered, option) {
    //           if (option.amount !== 0) {
    //             filtered.push(option["asset-id"])
    //           }
    //           return filtered
    //         }, []);
    //         console.log(resmain);
    //         if (IDS?.length > 0) {
    //           for (let i = 0; i < IDS.length; i++) {
    //             if (IDS[i] > 582353765 && IDS[i] < 584204890) {
    //
    //               algodClient.getAssetByID(IDS[i]).do().then((res => {
    //                 if (
    //                     res.params.creator === 'GOOSE4NW53JZLCG6NX37WWPUAOOYSAHPVPPEMBI7FGZZBZ4OET27JV4O3U' ||
    //                     res.params.creator === 'GOOSECHXVEKJ4SO43NTW5HXOIGLFGC2SQDAVWQGJCN576ODJ5SECV6MUOM' ||
    //                     res.params.creator === 'GOOSE7PN4S366W5LLQ3TRO4BCB2C66VBSMMXRVAWAPGZJHJYR34VNK2AU4' ||
    //                     res.params.creator === 'GOOSEKPIKOZPEPBMFO7TRRR2EPXLWKOIBLKXJKXWMK2J56SOXWRC3FLNSU' ||
    //                     res.params.creator === 'GOOSEOQSO2XM54KNCN2ESH3A7VCHRFSCFMACE24QLBUGCR256JOGRCYSSI'
    //                 ) {
    //                   if (cgfs === null)
    //                     setCgfs(toNum(res.params.name));
    //                 } else {
    //                 }
    //               }))
    //             }
    //           }
    //         }
    //       });
    //     }
    //
    // }, [cgfs, selectedWallet, gootBalance, walletType])

    return (
        <>
            <Header
                page={page}
                setPage={setPage}
                setGootBalance={setGootBalance}
                setSelectedWallet={setSelectedWallet}
                selectedWallet={selectedWallet}
                gootBalance={gootBalance}
                setWalletType={setWalletType}
                walletType={walletType}
                myAlgoWallet={myAlgoWallet}
                cgfs={cgfs}
                setCgfs={setCgfs}
                algodClient={algodClient}
            />
            {
                page === 'dashboard' ?
                    <Dashboard
                        setPage={setPage}
                        selectedWallet={selectedWallet}
                        cgfs={cgfs}
                    />
                    :
                    null
            }
            {
                page === 'honk' ?
                    <Honk
                        selectedWallet={selectedWallet}
                        gootBalance={gootBalance}
                        setGootBalance={setGootBalance}
                        walletType={walletType}
                        myAlgoWallet={myAlgoWallet}
                        algodClient={algodClient}

                    />
                    :
                    null
            }
            {
                page === 'raffle' ?
                    <Raffle
                        selectedWallet={selectedWallet}
                        gootBalance={gootBalance}
                        setGootBalance={setGootBalance}
                        walletType={walletType}
                        myAlgoWallet={myAlgoWallet}
                        algodClient={algodClient}
                    />
                    :
                    null
            }
            {
                page === 'admin' ?
                    <RaffleAdmin/>
                    :
                    null
            }
            {
                page === 'missions' ?
                    <Missions
                        selectedWallet={selectedWallet}
                        setPage={setPage}
                        walletType={walletType}
                        myAlgoWallet={myAlgoWallet}
                        algodClient={algodClient}
                        cgfs={cgfs}
                    />
                    :
                    null
            }
            {
                page === 'banner' ?
                    <Banner
                        selectedWallet={selectedWallet}
                    />
                    :
                    null
            }
            {
                page === 'rewards' ?
                    <Rewards
                        selectedWallet={selectedWallet}
                        walletType={walletType}
                        myAlgoWallet={myAlgoWallet}
                        algodClient={algodClient}
                    /> : null
            }
            <Footer/>
        </>
    )
}

export default App