import './Honk.css';
import honkGoose from './assets/Honkgoose.svg';
import algosdk from "algosdk";
import { useState } from 'react';
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";

const gootsAssetID = 751294723;

export default function Honk(props) {

    const [receiver, setReceiver] = useState();
    const [message, setMessage] = useState();
    const [discord, setDiscord] = useState();
    const [twitter, setTwitter] = useState();

    const sendHonk = (transaction) => {
        fetch(`${process.env.REACT_APP_API_URL}/honk`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                receiver: receiver,
                message: message,
                discord: discord,
                twitter: twitter,
                selectedWallet: props.selectedWallet,
                txId: transaction
            })
        })
            .then(res => res.json())
            .then(response => alert(response))
            .catch(err => alert(err))

    }


    const onSubmitAsaTransferTx = async () => {
        if (props.selectedWallet) {
            try {
                const params = await props.algodClient.getTransactionParams().do();
                const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                    suggestedParams: {
                        ...params,
                        fee: 1000,
                        flatFee: true,
                    },
                    from: props.selectedWallet,
                    to: 'HONKIYYNTEMTI6S35KAQTNJCOVFW7QXYW5SWAYT3OXWNFSS7JK6D4SQV2I',
                    amount: 5,
                    assetIndex: gootsAssetID
                });

                //walletconnect
                const txns = [txn]
                const txnsToSign = txns.map(txn => {
                    const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64");

                    return {
                        txn: encodedTxn,
                        message: 'Description of transaction being signed',
                        // Note: if the transaction does not need to be signed (because it's part of an atomic group
                        // that will be signed by another party), specify an empty singers array like so:
                        // signers: [],
                    };
                });

                const requestParams = [txnsToSign];

                const request = formatJsonRpcRequest("algo_signTxn", requestParams);

                const signedTxn = props.walletType === 'myalgo' ?
                    await props.myAlgoWallet.signTransaction(txn.toByte())
                    :
                    await props.connector.sendCustomRequest(request);
                console.log(signedTxn)
                if (props.walletType === 'myalgo') {
                    const response = await props.algodClient.sendRawTransaction(signedTxn.blob).do();
                    console.log(response.txId);
                }
                await algosdk.waitForConfirmation(props.algodClient, txn.txID().toString(), 4)
                sendHonk(txn.txID().toString());
                props.setGootBalance(props.gootBalance - 5);

            }
            catch (err) {
                alert(err);

            }
        } else {
            alert('Wallet not connected')
        }

    }

    return (
        <div className='honk-wrapper'>
            <div className='honk-instructions'>
                <div>
                    <h1 className='headone'>Honk</h1>
                    <br>
                    </br>
                    <div className='honk-text'>
                        Have you found the NFT you've been looking for, but don't
                        know how to <strong>contact the owner</strong>? Want to contact your
                        web3 love? Want to send your friend a tx when they
                        blocked you on social media?
                        <strong style={{ color: '#dd67ff' }}> Honk</strong> is your solution! Each Honk costs <strong>5 $CRUMB</strong> and
                        0.001 ALGO for the transaction {'(every message is on chain -> permanent/immutable)'}
                    </div>
                    <br></br>
                    <br></br>
                </div>
                <img className='honk-goose' src={honkGoose} alt="honk-goose" />
            </div>
            <div className='honk-form-wrapper'>
                <div className='honk-form'  >
                    <input
                        className='honk-input top'
                        placeholder='Enter Wallet'
                        onChange={(e) => { setReceiver(e.target.value); }}
                    />

                    <textarea
                        className='honk-input bottom'
                        placeholder='Write your message...'
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <div className='honk-social'>
                        <input
                            className='social honk-input  '
                            placeholder='Discord Handle'
                            onChange={(e) => setDiscord(e.target.value)}
                        />
                        <input
                            className=' social honk-input'
                            placeholder='@Twitter'
                            onChange={(e) => setTwitter(e.target.value)}
                        />

                    </div>
                    <div className='honk-button-wrapper'>
                        <button className='honk-button' onClick={onSubmitAsaTransferTx}>SEND MESSAGE</button>
                    </div>
                </div>
            </div>
        </div>
    )
}