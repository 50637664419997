import './ConnectWalletBox.css'
import peraLogo from './assets/pera.svg';
import myalgoLogo from './assets/myalgo.svg'
import algosdk from 'algosdk';
import { useState, useEffect, useRef } from 'react';
import { PeraWalletConnect } from "@perawallet/connect";

const gootsAssetID = 751294723;

export default function ConnectWalletBox(props) {

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.setConnectBox(false);
                }
            }
            // Bind the event listener
            document.addEventListener("click", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("click", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const getGooseBalance = async (wallet) => {
        await props.algodClient.accountInformation(wallet).do()
            .then((res) =>
                res.assets.map((i) => {
                    if (i["asset-id"] === gootsAssetID) {
                        props.setGootBalance(i.amount)
                    }
                })
            );
    }

    const getCGF = (wallet) => {

        const toNum = (value) => {
            return Number(value.replace('CGF #', ''))
        }


        if (!wallet) return;


        props.algodClient.accountInformation(wallet).do().then(resmain => {

            const IDS = resmain.assets.reduce(function (filtered, option) {
                if (option.amount !== 0) {
                    filtered.push(option["asset-id"])
                }
                return filtered
            }, []);

            console.log(resmain);


            if (IDS?.length > 0) {
                for (let i = 0; i < IDS.length; i++) {
                    if (IDS[i] > 582353765 && IDS[i] < 584204890) {

                        props.algodClient.getAssetByID(IDS[i]).do().then((res => {
                            if (
                                res.params.creator === 'GOOSE4NW53JZLCG6NX37WWPUAOOYSAHPVPPEMBI7FGZZBZ4OET27JV4O3U' ||
                                res.params.creator === 'GOOSECHXVEKJ4SO43NTW5HXOIGLFGC2SQDAVWQGJCN576ODJ5SECV6MUOM' ||
                                res.params.creator === 'GOOSE7PN4S366W5LLQ3TRO4BCB2C66VBSMMXRVAWAPGZJHJYR34VNK2AU4' ||
                                res.params.creator === 'GOOSEKPIKOZPEPBMFO7TRRR2EPXLWKOIBLKXJKXWMK2J56SOXWRC3FLNSU' ||
                                res.params.creator === 'GOOSEOQSO2XM54KNCN2ESH3A7VCHRFSCFMACE24QLBUGCR256JOGRCYSSI'
                            ) {
                                if (props.cgfs === null)
                                    props.setCgfs(toNum(res.params.name));
                            } else {
                            }
                        }))
                    }

                }
            }

        });

    }

    //myAlgo connect
    const connectToMyAlgo = async () => {
        try {
            const settings = {
                shouldSelectOneAccount: true,
                openManager: false
            };
            const accounts = await props.myAlgoWallet.connect(settings);
            // console.log(accounts);

            const wallets = accounts.map(account => account.address);
            // console.log(_wallets)

            props.setSelectedWallet(wallets[0]);
            props.setWalletType('myalgo');
            localStorage.setItem('selectedWallet', wallets[0])
            localStorage.setItem('walletType', 'myalgo')
            await getGooseBalance(wallets[0]);
            getCGF(wallets[0])

        } catch (err) {
            console.error(err);
        }
    };



    const walletConnectInit = async () => {
        try {
            const peraWallet = new PeraWalletConnect();
                    peraWallet.connect()
                        .then(async (newAccounts) => {
                            props.setSelectedWallet(newAccounts[0])
                            props.setWalletType("pera")
                            await getGooseBalance(newAccounts[0])
                            getCGF(newAccounts[0])
                        }).catch(e => {
                            peraWallet.reconnectSession().then(async (accounts) => {
                                if(accounts.length) {
                                    props.setSelectedWallet(accounts[0])
                                    props.setWalletType("pera")
                                    await getGooseBalance(accounts[0])
                                    getCGF(accounts[0])
                                } else {
                                    console.log(e)
                                }

                            }).catch(e => console.log(e))
                    })
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div ref={wrapperRef} className="connect-container">
            <div className='wallettype'>
                <img className='connect-logo' src={peraLogo} alt="pera logo" onClick={walletConnectInit} />
                <div className='connection-status'>
                    <div className='status'>
                        <div className='type' onClick={walletConnectInit}>Pera Wallet</div>
                        <div className='connection-text' style={{ color: props.selectedWallet && props.walletType === 'pera' ? '#dd67ff' : '#d2d2d2' }} onClick={walletConnectInit}>
                            {
                                props.selectedWallet && props.walletType === 'pera' ?
                                    'Connected' :
                                    'Not Connected'
                            }
                        </div>
                    </div>
                    {props.walletType === 'pera' ?
                        <div className='status'>
                            <div className='type'>{props.selectedWallet.slice(0, 5) + '...' + props.selectedWallet.substr(props.selectedWallet.length - 5)}</div>
                            <div className='disconnect-button' onClick={async () => {
                                props.setSelectedWallet(null);
                                props.setWalletType(null);
                                props.setGootBalance(null);
                                props.setSelectedWallet(null);
                                const peraWallet = new PeraWalletConnect();
                                await peraWallet.disconnect()
                                localStorage.removeItem('selectedWallet')
                                localStorage.removeItem('walletType')
                                props.setCgfs(null)
                            }}>
                                DISCONNECT
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}