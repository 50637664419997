import { useState, useEffect } from "react";
import './RaffleAdmin.css';
import algosdk from 'algosdk';

const indexerClient = new algosdk.Indexer('', 'https://mainnet-idx.algonode.cloud	', '');

export default function RaffleAdmin() {

    const [asa, setASA] = useState();
    const [id, setID] = useState();
    const [date, setDate] = useState();
    const [winner, setWinner] = useState();
    const [entries, setEntries] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetch('http://pond.crazygooseflock.com/dashboardPhp/returnAdmin.php')
            .then(res => res.json())
            .then(response => {
                console.log(response)
                setASA(response.asa);
                setID(response.id);
                setDate(response.date);
                setWinner(response.winner)
            })
            .catch(err => console.log(err));

        fetch('https://pond.crazygooseflock.com/dashboardPhp/returnEntries.php')
            .then(res => res.json())
            .then(response => {
                setEntries(response);

            })

    }, []);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            marginBottom: '5%',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div>enter blank date and blank winner to pause raffle.</div>
            <div>do not enter a winner before raffle has expired</div>
            <div>
                <div>
                    <input
                        placeholder="May 30 2022 19:37:25"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        type="number"
                        placeholder="prize CGF#..."
                        value={id}
                        onChange={(e) => setID(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        type="number"
                        placeholder="ASA ID"
                        value={asa}
                        onChange={(e) => setASA(e.target.value)}
                    />
                </div>

                <div>
                    <input
                        type="winner wallet"
                        placeholder="winner address"
                        value={winner}
                        onChange={(e) => setWinner(e.target.value)}
                    />
                </div>
                <br></br>
                <button onClick={() => {
                    fetch(`https://pond.crazygooseflock.com/dashboardPhp/updateAdmin.php?date=${date}&asa=${asa}&id=${id}&winner=${winner}`)
                        .catch(err => console.log(err));

                }}>
                    UPDATE RAFFLE
                </button>
                <br></br>
                <br></br>
                <button
                    onClick={() => {
                        fetch('https://pond.crazygooseflock.com/dashboardPhp/deleteEntries.php')
                            .then(res => console.log(res))
                            .catch(err => console.log(err))
                    }}
                >
                    CLEAR ALL ENTRIES
                </button>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>Wallets</th>
                        <th>Tickets</th>
                    </tr>
                    {
                        entries?.map((i) => <tr>
                            <td
                            // style={{ color: checkCheat(i.wallet) }}
                            >{i.wallet}</td>
                            <td>{i.tickets}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}