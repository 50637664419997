import { useEffect, useState, useRef } from 'react';
import MyAlgoWallet from '@randlabs/myalgo-connect';
import algosdk from 'algosdk';
import { toPng } from 'html-to-image';
import './Banner.css';
import './assets/Montserrat-Regular.ttf';
import './assets/Beautiful-Freak.otf';
import "./assets/Montserrat-Bold.ttf";
import HeaderSwitch from './components/HeaderSwitch/HeaderSwitch';
import ColorPicker from './components/ColorPicker/ColorPicker';
import CustomText from './components/CustomText/CustomText';
import GoosePicker from './components/GoosePicker/GoosePicker';
import leftCaret from './assets/left-caret.png';
import rightCaret from './assets/right-caret.png';
import Drag from './Drag';
import logo from './assets/logo192.png';



const algodClient = new algosdk.Algodv2('', 'https://mainnet-api.algonode.cloud', '');


function Banner(props) {

  const [wallets, setWallets] = useState();
  const [connectStart, setConnectStart] = useState(false)
  const [selectedWallet, setSelectedWallet] = useState();
  const colors = ['#f9bf3d', '#ef923f', '#45a051', '#5387bb', '#d3d3d3', '#7c5ea5', '#2fe07b', '#92763e',];
  const carousel = ['Background', 'Header', 'Custom Text', 'Your CGFs'];
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [mobile, setMobile] = useState()
  const [selectedColor, setSelectedColor] = useState('#fff');
  const [customText, setCustomText] = useState();
  const [isHeader, setHeader] = useState(true);
  const [isResizeLarge, setResizeLarge] = useState(true);
  const [gooseIds, setGooseIds] = useState();
  const [gooseNames, setGooseNames] = useState([]);
  const [gooseUrls, setGooseUrls] = useState([]);

  const ref = useRef();

  useEffect(() => {


    if (window.innerWidth < 700) {
      setMobile(true);
    } else {
      setMobile(false)
    }
    (async () => {

      if (!props.selectedWallet) return;

      let accountInfo = await algodClient.accountInformation(props.selectedWallet).do();
      // console.log(accountInfo);

      const IDS = accountInfo.assets.reduce(function (filtered, option) {
        if (option.amount !== 0) {
          filtered.push(option["asset-id"])
        }
        return filtered;
      }, []);

      setGooseIds(IDS);
      // console.log("GOOSE IDS", IDS)

      //retrieve goose names from ID number
      if (IDS.length > 0) {
        let arr = []
        for (let i = 0; i < IDS.length; i++) {

          if (IDS[i] > 582353765 && IDS[i] < 584204890) {
            // console.log("pushing")
            algodClient.getAssetByID(IDS[i]).do().then((res => {
              // console.log(res.params)
              if (
                res.params.creator === 'GOOSE4NW53JZLCG6NX37WWPUAOOYSAHPVPPEMBI7FGZZBZ4OET27JV4O3U' ||
                res.params.creator === 'GOOSECHXVEKJ4SO43NTW5HXOIGLFGC2SQDAVWQGJCN576ODJ5SECV6MUOM' ||
                res.params.creator === 'GOOSE7PN4S366W5LLQ3TRO4BCB2C66VBSMMXRVAWAPGZJHJYR34VNK2AU4' ||
                res.params.creator === 'GOOSEKPIKOZPEPBMFO7TRRR2EPXLWKOIBLKXJKXWMK2J56SOXWRC3FLNSU' ||
                res.params.creator === 'GOOSEOQSO2XM54KNCN2ESH3A7VCHRFSCFMACE24QLBUGCR256JOGRCYSSI'
              ) {
                setSelectedColor('#fff')
                arr.push(res.params.name);
              } else {
              }
            }))
          }

        }

        setGooseNames(arr);
      }


    })();



  }, [props.selectedWallet]);



  const saveImage = () => {


    toPng(ref.current)
      .then((dataUrl) => {
        toPng(ref.current)
          .then((dataUrl) => {
            toPng(ref.current)
              .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = 'CGFbanner.png'
                link.href = dataUrl
                link.click()
                setResizeLarge(true)
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })

  }


  return (
    <>
      {!isResizeLarge ?
        <div className='blocker'>
          <img src={logo} height='192' width='192' alt="Loading" />
          <div>Saving...</div>
        </div>
        :
        null
      }
      <div className="app-container">
        <div className='banner-header'>
          <div className='heading-title'>Banner Builder</div>
          <div className='heading-text'>
            Your CGF pfp doesn't have to be all alone anymore! Showcase your beutiful Geese, all at once, with our veracity Banner Builder.
            You can choose the background, header, custom text and which of your favourite CGFs you want to display (6 CGFs max) [Image loading might take a few seconds]
          </div>
        </div>

        <div
          ref={ref}
          className='image-container'
          style={{
            background: selectedColor,
            borderRadius: isResizeLarge ? '20px' : '0px',
            position: isResizeLarge ? 'relative' : 'absolute',
            left: isResizeLarge ? '' : '0',
            width: isResizeLarge ? '' : `${mobile ? '750px' : '1500px'} `,
            height: isResizeLarge ? `${mobile ? '33vw' : 'auto'}` : `${mobile ? '250px' : '500px'} `
          }}
        >

          <Drag
            gooseurls={gooseUrls}
            setGooseUrls={setGooseUrls}
            color={selectedColor}
          />

          {gooseNames.length === 0 && selectedWallet ?
            <div className='disclaimer'>Looks like you don't have a CGF</div>
            :

            <div className='gooselogo-text' style={{
              visibility: isHeader ? 'visible' : 'hidden',
              fontSize: isResizeLarge ? `${mobile ? '9vw' : '6vw'}` : `${mobile ? '75px' : '155px'}`,
            }}
            >crazy&nbsp;goose&nbsp;&nbsp;flock
            </div>

          }
          {
            props.selectedWallet === null ?
              <div className='banner-unconnected'>Connect Wallet</div> :
              null
          }

          {
            props.selectedWallet && gooseNames.length > 0 &&
            <div
              className='text-display'
              style={{
                width: customText ? 'auto' : '18%',
                border: !isResizeLarge ? 'none' : '2px solid white',
                top: isHeader ? '25%' : '10%',
                fontSize: isResizeLarge ? '1vw' : `${mobile ? '4vw' : '2vw'}`
              }}
            >
              {customText}
            </div>
          }
        </div>
        {mobile && props.selectedWallet ?
          <div className='phone-carousel'>

            <img
              style={{
                visibility: carouselIndex !== 0 ? 'visible' : 'hidden',
                marginLeft: '1%'
              }}
              onClick={() => setCarouselIndex(carouselIndex - 1)}
              className='caret'
              src={leftCaret}
              alt="<"
            />
            {carousel[carouselIndex]}
            <img
              style={{
                visibility: carouselIndex !== 3 ? 'visible' : 'hidden',
                marginRight: '1%'
              }}
              onClick={() => setCarouselIndex(carouselIndex + 1)}
              className='caret'
              src={rightCaret}
              alt=">"
            />
          </div>
          :
          null
        }

        {props.selectedWallet &&
          < div className='edit-container'>
            {mobile ?
              <>
                <>
                  <ColorPicker display={carouselIndex === 0 ? '' : 'none'} colors={colors} setColor={setSelectedColor} selectedColor={selectedColor} />


                  <HeaderSwitch display={carouselIndex === 1 ? '' : 'none'} header={isHeader} setheader={setHeader} />

                  <CustomText display={carouselIndex === 2 ? '' : 'none'} setCustomText={setCustomText} />

                  <GoosePicker display={carouselIndex === 3 ? '' : 'none'} names={gooseNames} gooseurls={gooseUrls} setGooseUrls={setGooseUrls} />

                </>

                <button className='btn-main btn-save' disabled={gooseNames.length > 0 ? false : true} onClick={() => {
                  setResizeLarge(false)
                  setTimeout(saveImage, 100)
                }}>SAVE</button>

              </>

              :
              <>
                <GoosePicker names={gooseNames} gooseurls={gooseUrls} setGooseUrls={setGooseUrls} />
                <ColorPicker colors={colors} setColor={setSelectedColor} selectedColor={selectedColor} />
                <HeaderSwitch header={isHeader} setheader={setHeader} />
                <div className="bottom-row">
                  <CustomText setCustomText={setCustomText} />
                  <button className='btn-main btn-save' disabled={gooseNames.length > 0 ? false : true} onClick={() => {
                    setResizeLarge(false)
                    setTimeout(saveImage, 100)
                  }}>SAVE</button>
                </div>
              </>
            }

          </div>
        }
      </div>
    </>
  );
}

export default Banner;
