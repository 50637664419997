import './styles.css'

export default function CustomText(props) {
    return (
        <div style={{ display: props.display }} className="row-wrap-text">
            <div className='title-content'>Custom text:</div>
            <input
                className='text-input'
                onInput={(e) => props.setCustomText(e.target.value)}
            />
        </div>
    )
}