import './styles.css'


export default function HeaderSwitch(props) {
    return (
        <div style={{ display: props.display }} className="row-wrap">
            <div className='title'>Header:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="toggle-switch">
                <input
                    type="checkbox"
                    className="toggle-switch-checkbox"
                    name="toggleSwitch"
                    id="toggleSwitch"
                    onInput={() => { props.setheader(!props.header); console.log(props.header) }}
                />
                <label className="toggle-switch-label" htmlFor="toggleSwitch">
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                </label>

            </div>
        </div>
    )
}