import './Footer.css';
import twitterIcon from './assets/twitter.svg';
import discordIcon from './assets/discord.svg'

export default function Footer() {
    return (
        <div className="footer-container">
            <div>
                Copyright © {new Date().getFullYear()} CGF
            </div>
            <div className='footer-link'>
                <div className='twitter' onClick={() => { window.open('https://discord.gg/zqq96srMY8') }} >
                    <img className='discord-icon' src={discordIcon} alt="" />
                </div>
                <div className='discord' onClick={() => { window.open('https://twitter.com/CrazyGooseFlock') }}>
                    <img className='discord-icon' src={twitterIcon} alt="" />
                </div>
            </div>
            <div className='terms'>
                <div>
                    <a className='terms-link' href='http://crazygooseflock.com/terms-and-conditions/'>CGF Terms & Conditions</a>

                </div>
                <div>&nbsp;</div>
                <div>
                    Powered by CGF
                </div>
            </div>
        </div>
    )
}