import './Raffle.css';
import tickets from './assets/tickets.svg';
import {useEffect, useState} from 'react';
import {formatJsonRpcRequest} from "@json-rpc-tools/utils";
import CountDownTimerMobile from './CountDownTimerMobile';
import confetti from './assets/party.svg';
import hug from './assets/hug.svg';
import derpgoose from './assets/derp.png';
import hammer from './assets/hammer.svg'
import PeraModal from './peraModal/peraModal';
import algosdk, {assignGroupID} from 'algosdk';
import axios from "axios";
import {PeraWalletConnect} from "@perawallet/connect";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


const gootsAssetID = 751294723;

export default function Raffle(props) {

    const [ticketAmount, setTicketAmount] = useState(0);
    const [asa, setASA] = useState();
    const [id, setID] = useState();
    const [date, setDate] = useState();
    const [winner, setWinner] = useState();
    const [loading, setLoading] = useState(false);
    const [isPeraModal, setPeraModal] = useState(true);
    const [imageUrl, setImageUrl] = useState()
    const [name, setName] = useState()
    const [myTickets, setMyTickets] = useState()
    const [raffleId, setRaffleId] = useState()
    const [ticketNumber, setTicketNumber] = useState(0);
    const [winnerWallet, setWinnerWallet] = useState('')
    const [walletLimit, setWalletLimit] = useState()
    const [ticketLimit, setTicketLimit] = useState()

    useEffect(() => {
        if (props.selectedWallet === null) {
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/raffles/${props.selectedWallet}`)
                .then(res => res.json())
                .then(response => {
                        if(response.raffle !== undefined){
                        const raffle = response.raffle
                        console.log(raffle)
                        setASA(raffle.assetId)
                        setDate(raffle.endingAt)
                        setImageUrl(raffle.assetUrl)
                        setName(raffle.assetName)
                        setID(raffle.assetName)
                        setRaffleId(raffle.id)
                        setTicketNumber(raffle.entries)
                        setMyTickets(raffle.walletEntries)
                        setWalletLimit(raffle.walletLimit)
                        setTicketLimit(raffle.ticketLimit)
                    } else {
                            if(response.lastRaffle !== undefined) {
                                const id = response.lastRaffle.assetName.toString().substr(5, 10)
                                setID(id)
                                setWinnerWallet(response.lastRaffle.winner)
                            }
                        }
                })
        }
    }, [loading, props.selectedWallet])

    const countDownDate = new Date(date).getTime();


    function isInThePast(date) {
        const today = new Date();
        return date < today;
    }

    const optIn = async () => {
        try {
            const params = await props.algodClient.getTransactionParams().do();
            const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                suggestedParams: {
                    ...params,
                    fee: 1000,
                    flatFee: true,
                },
                from: props.selectedWallet,
                to: props.selectedWallet,
                amount: 0,
                assetIndex: Number(asa)
            });


            const signedTxn =
                await props.myAlgoWallet.signTransaction(txn.toByte())

            console.log(signedTxn);
            const response = await props.algodClient.sendRawTransaction(signedTxn.blob).do();
            console.log(response.txId);
            claimPrize();


        } catch (err) {
            // alert(err);
            console.log(err)

        }
    }


    const onSubmitAsaTransferTx = async () => {
        setLoading(true)
        if (props.selectedWallet) {
            try {
                if(ticketAmount === 0) {
                    alert("No tickets selected")
                    setLoading(false)
                } else if(myTickets === walletLimit) {
                    alert('You already have the maximum numbers of entries for this raffle!')
                    setLoading(false)
                } else if((+myTickets + +ticketAmount) > walletLimit) {
                    alert('You need to choose a lower number of tickets! You will go over the wallet limit!')
                    setLoading(false)
                } else if((+ticketAmount + +ticketNumber) > ticketLimit) {
                    alert('You need to choose a lower number of tickets. Not enough tickets available!')
                    setLoading(false)
                } else {
                    const params = await props.algodClient.getTransactionParams().do();
                    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                        suggestedParams: {
                            ...params,
                            fee: 1000,
                            flatFee: true,
                        },
                        from: props.selectedWallet,
                        to: 'HONKIYYNTEMTI6S35KAQTNJCOVFW7QXYW5SWAYT3OXWNFSS7JK6D4SQV2I',
                        amount: Number(ticketAmount),
                        assetIndex: gootsAssetID
                    });

                    const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                        suggestedParams: {
                            ...params
                        },
                        from: props.selectedWallet,
                        to: props.selectedWallet,
                        assetIndex: Number(asa),
                        amount: 0
                    })
                    const txnArray = [
                        txn,
                        optInTxn
                    ]

                    if(props.walletType === "pera") {
                        const peraWallet = new PeraWalletConnect();
                        await peraWallet.reconnectSession()
                        const signedTxn = await peraWallet.signTransaction([[
                            {txn: txn, signers: [props.selectedWallet]},
                            {txn: optInTxn, signers: [props.selectedWallet]}
                        ]])
                        for (const signedTxnGroup of signedTxn) {
                            await props.algodClient.sendRawTransaction(signedTxnGroup).do();
                        }
                    } else {
                        assignGroupID(txnArray)
                        let signedTxn = await props.myAlgoWallet.signTransaction(txnArray.map(txn => txn.toByte()));
                        const signedTxnArray = signedTxn.map(
                            (signedTxn) => signedTxn.blob
                        );
                        await props.algodClient.sendRawTransaction(signedTxnArray).do()
                    }
                    await algosdk.waitForConfirmation(props.algodClient, txn.txID(), 4)
                    await axios.post(`${process.env.REACT_APP_API_URL}/raffles/${raffleId}`, {
                        raffleId: raffleId,
                        wallet: props.selectedWallet,
                        txId: txn.txID()
                    })
                    setLoading(false)
                }

            } catch (err) {
                setLoading(false)
                alert(err);

            }
        } else {
            setLoading(false)
            alert('Wallet not connected')
        }

    }

    const claimPrize = () => {
        console.log('claimprize called')

        fetch('https://seahorse-app-bo2d4.ondigitalocean.app/api', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: 'claim'
            })
        })
            .then(res => res.json())
            .then(response => console.log(response))
            .catch(err => console.log(err))

    }

    const checkEmpty = (data) => {

        if (data === null || data === '' || data === undefined) {
            return true
        } else return false

    }

    const raffleOptions = (position) => {
        if (position === 'top') {

            if (checkEmpty(date) === false && !isInThePast(countDownDate) && checkEmpty(winner) === true) {
                console.log('timershowing')
                return <CountDownTimerMobile targetDate={countDownDate}/>
            } else if(props.selectedWallet === null) {
                return <div className='checkback'>Please Connect Your Wallet!</div>
            }
            else if (checkEmpty(date) === true && checkEmpty(winner) === true) {
                return <div className='checkback'>Last Winner: {winnerWallet.substring(0,6)} ... {winnerWallet.substring(19,25)}</div>
            } else if (isInThePast(countDownDate) && checkEmpty(winner) === false) {
                if (winner === props.selectedWallet) {
                    return <div className='you-won'>
                        <img className='confetti' src={confetti} alt=""/>
                        &nbsp;
                        YOU WON
                        &nbsp;
                        <img className='confetti' src={confetti} alt=""/>
                    </div>
                } else {
                    return <div className='you-lost'>
                        <img className='confetti' src={hug} alt=""/>
                        &nbsp;
                        No NFT for you
                        &nbsp;
                        <img className='confetti' src={hug} alt=""/>
                    </div>
                }
            } else if (checkEmpty(date) === false && isInThePast(countDownDate) && checkEmpty(winner)) {
                return <div className='raffle-ended'>Raffle Ended</div>
            }
        }
        //BOTTOM TEXT
        else {
            if (checkEmpty(date) === false && !isInThePast(countDownDate) && checkEmpty(winner) === true) {
                return (
                    <>
                        <div className='balance'>
                            <div className='balance-label'>
                                Your Balance
                            </div>
                            <br></br>
                            {props.gootBalance ?
                                <div className='balance-amount'>
                                    ${numberWithCommas(props.gootBalance)}.00 <span
                                    style={{color: '#dd67ff'}}>$CRUMB</span>
                                </div>
                                :
                                <div className='balance-amount'>
                                    $0.00 <span style={{color: '#dd67ff'}}>$CRUMB</span>
                                </div>
                            }

                        </div>

                        <div className='buy-form'>
                            <input
                                type='number'
                                className='raffle-input'
                                placeholder='Enter Number of Tickets'
                                onChange={(e) => {
                                    setTicketAmount(e.target.value);
                                    console.log(ticketAmount)
                                }}
                            />
                            <div className='buy-wrapper'>
                                <button

                                    className='buy'
                                    onClick={() => {
                                        if (tickets > props.gootBalance) {
                                            alert("Insufficient funds ");

                                        } else {
                                            onSubmitAsaTransferTx();
                                        }
                                    }}
                                >
                                    BUY & ENTER
                                </button>
                            </div>
                            <div>
                                My Tickets :&nbsp;{myTickets} <br/>
                                My Odds: {myTickets !== 0 ? <>{((myTickets / ticketNumber)*100).toFixed(4)}</> : 0}
                                % <br/>
                                Wallet Ticket Limit: {walletLimit} <br/>
                                Tickets Left: {ticketLimit - ticketNumber}
                            </div>


                        </div>
                    </>
                )
            } else if (checkEmpty(date) && checkEmpty(winner) && props.selectedWallet !== null) {
                return <div className='working'>
                    Your trusted goots are <br></br>
                    working on the next raffle<br></br>
                    <img className='hammer' src={hammer} alt=""/>
                </div>
            } else if (checkEmpty(date) === false && isInThePast(countDownDate) && checkEmpty(winner) === false) {
                if (winner === props.selectedWallet) {
                    return <button
                        className='claim'
                        onClick={() => {
                            if (props.walletType === 'myalgo') {
                                optIn();
                            } else {
                                claimPrize();
                            }
                        }}
                    >CLAIM</button>
                } else {
                    return <div className='better-luck'>Better luck next time...</div>
                }
            } else if (date && isInThePast(countDownDate) && !winner) {
                return <div className='picked-soon'>Winner being picked!</div>
            }

        }
    }

    return (
        <div className='raffle-container'>
            {
                isPeraModal ?
                    <PeraModal
                        asa={asa}
                        setPeraModal={setPeraModal}
                        claimPrize={claimPrize}
                    />
                    :
                    null

            }

            <div className='raffle-header'>
                <div className='raffle-title'>
                    <div className='raffle-title-h1'>
                        Holders
                    </div>
                    <div style={{color: '#dd67ff'}} className='raffle-title-h1'>
                        Raffle
                    </div>
                    <br></br>
                    <div className='raffle-info'>
                        Buy tickets with $CRUMB for the raffle <span
                        style={{color: '#dd67ff'}}>1 $CRUMB = 1 Ticket</span><br></br>
                        After each Raffle is complete the prize is automatically  <br></br>
                        distributed to the winner
                    </div>
                </div>
                <img className='tickets-image' src={tickets} alt="tickets"/>
            </div>

            {raffleOptions('top')}

            {loading ?
                <div className='prize-img-container'>
                    <img
                        src='https://pond.b-cdn.net/Loading%20Animation.GIF'
                    />
                    {id ? <div className='prize-label'>{id}</div> : null}
                    {raffleOptions('bottom')}
                </div>

                :
                <div className='prize-img-container'>
                    <img
                        className='prize-img'
                        src={checkEmpty(id) ?
                            derpgoose
                            :
                            `https://www.randswap.com/v1/nft-url/${asa}`
                            // "https://ipfs.algonft.tools/ipfs/bafybeiftkk7bphkl4vpfaj34yjlz66spugbva57tr7qhkuidifw6zfyw7u"
                        }
                        alt="something went wrong..."
                        onClick={() => {
                            window.open(`https://www.randgallery.com/algo-collection/?address=${asa}`)
                        }}
                    />
                    {id ? <div className='prize-label'>{id}</div> : null}
                    {raffleOptions('bottom')}
                </div>
            }

        </div>
    )
}