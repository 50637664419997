import { Button, CardActionArea, CardActions, Grid, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import {useEffect, useState} from "react";
import algosdk, {assignGroupID} from "algosdk";
import axios from "axios";
import {PeraWalletConnect} from "@perawallet/connect";

export default function Rewards(props){
    const [loading,setLoading] =useState(false)
    const [otherRewards, setOtherRewards] = useState([
        {
            "id": 1,
            "wallet": "JQOQABKD5YXNTS3DAK2S3JN3ZXMENK2SDEBY5TMFTFTAR6PDCOZCOY7AGQ",
            "asssetId": 388448344,
            "claimed": false,
            "amount": 0
        }
    ])
    const [rewards, setRewards]  = useState( [
        {
            id: 0,
            name: '$CRUMB',
            imageUrl: 'https://ipfs.algonft.tools/ipfs/QmXyG61XQMyMabstFc5RQeC2eFVZHkSVauWDM8QzYMareS?optimizer=true',
            asaId: 751294723,
            disabled: true,
            amount: 0
        }
    ])
    const [redeemRewards, setRedeemRewards] = useState()
    useEffect(() => {
        if (props.selectedWallet === null) {
            alert('Connect your wallet')
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/mission/rewards/${props.selectedWallet}`)
                .then(res => res.json())
                .then(async data => {
                    const crumbDisabled = (data.crumb <= 0)
                    setRewards(
                        [
                            {
                                id: 0,
                                name: '$CRUMB',
                                imageUrl: 'https://ipfs.algonft.tools/ipfs/QmXyG61XQMyMabstFc5RQeC2eFVZHkSVauWDM8QzYMareS?optimizer=true',
                                asaId: 751294723,
                                disabled: crumbDisabled,
                                amount: data.reward.crumb
                            }
                        ]
                    )
                    const otherRewards = data.otherRewards;
                    for (const reward in otherRewards) {
                        otherRewards[reward].assetName = await assetInformation(otherRewards[reward].asssetId)
                    }
                    setOtherRewards(otherRewards)
                })
        }
    }, [loading])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/rewards/redeem/${props.selectedWallet}`)
            .then(res => res.json())
            .then(async data => {
                const rewards = data;
                if (rewards.length > 0) {
                    for (const reward in rewards) {
                        rewards[reward].assetName = await assetInformation(rewards[reward].asssetId)
                    }
                    setRedeemRewards(rewards)
                }
            })
    }, [loading])


    const optIn = async () => {
        try {
            setLoading(true)
            const params = await props.algodClient.getTransactionParams().do();
            const optInTx = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                suggestedParams: {
                    ...params
                },
                from: props.selectedWallet,
                to: props.selectedWallet,
                assetIndex: Number(751294723),
                amount: 0
            })
            let signedTxn
            if (props.walletType === "pera") {
                const peraWallet = new PeraWalletConnect();
                await peraWallet.reconnectSession()
                const txnToSign = [{txn: optInTx, signers: [props.selectedWallet]}]
                signedTxn = await peraWallet.signTransaction([txnToSign])
                await props.algodClient.sendRawTransaction(signedTxn).do();
            } else {
                signedTxn = await props.myAlgoWallet.signTransaction(optInTx.toByte())
                await props.algodClient.sendRawTransaction(signedTxn.blob).do();
            }
            const transactionId = optInTx.txID()
            await algosdk.waitForConfirmation(props.algodClient, transactionId, 4)
            await axios.post(`${process.env.REACT_APP_API_URL}/missions/claim/${props.selectedWallet}`)
            setLoading(false)
            alert('Crumb Claimed!')
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    const redeemRewardsTx = async (assetId) => {
        try{
            setLoading(true)
            const params = await props.algodClient.getTransactionParams().do();
            const optInTx = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                suggestedParams: {
                    ...params
                },
                from: props.selectedWallet,
                to: props.selectedWallet,
                assetIndex: Number(751294723),
                amount: 0
            })
            const sendTx = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                suggestedParams: {
                    ...params
                },
                from: props.selectedWallet,
                to: "GOOTY35W2GXX36NI2XC77UHALFSWSP5PW7VAGR55A2R52YNL7AEJYVQ2V4",
                assetIndex: Number(assetId),
                amount: 1
            })
            const txnArray = [
                optInTx,
                sendTx
            ]
            if(props.walletType === "pera") {
                const peraWallet = new PeraWalletConnect();
                await peraWallet.reconnectSession()
                const signedTxn = await peraWallet.signTransaction([[
                    {txn: optInTx, signers: [props.selectedWallet]},
                    {txn: sendTx, signers: [props.selectedWallet]}
                ]])
                for (const signedTxnGroup of signedTxn) {
                    await props.algodClient.sendRawTransaction(signedTxnGroup).do();
                }
            } else {
                assignGroupID(txnArray)
                let signedTxn = await props.myAlgoWallet.signTransaction(txnArray.map(txn => txn.toByte()));
                const signedTxnArray = signedTxn.map(
                    (signedTxn) => signedTxn.blob
                );
                await props.algodClient.sendRawTransaction(signedTxnArray).do()
            }
            const txId = sendTx.txID()
            await algosdk.waitForConfirmation(props.algodClient, txId, 4)
            await axios.post(`${process.env.REACT_APP_API_URL}/rewards/claim/asset`, {
                wallet: props.selectedWallet,
                txId,
                assetId: Number(assetId)
            })
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    const assetInformation = async(assetId) => {
        const info = await props.algodClient.getAssetByID(assetId).do()
        return info.params.name
    }

    const optInReward = async (assetId, rewardId) => {
        try {
            setLoading(true)
            const params = await props.algodClient.getTransactionParams().do();
            const optInTx = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                suggestedParams: {
                    ...params
                },
                from: props.selectedWallet,
                to: props.selectedWallet,
                assetIndex: Number(assetId),
                amount: 0
            })
            let signedTxn
            if (props.walletType === "pera") {
                const peraWallet = new PeraWalletConnect();
                await peraWallet.reconnectSession()
                const txnToSign = [{txn: optInTx, signers: [props.selectedWallet]}]
                signedTxn = await peraWallet.signTransaction([txnToSign])
                await props.algodClient.sendRawTransaction(signedTxn).do();
            } else {
                signedTxn = await props.myAlgoWallet.signTransaction(optInTx.toByte())
                await props.algodClient.sendRawTransaction(signedTxn.blob).do();
            }
            const transactionId = optInTx.txID()
            await algosdk.waitForConfirmation(props.algodClient, transactionId, 4)
            await axios.post(`${process.env.REACT_APP_API_URL}/missions/claim/rewards/${rewardId}`)
            setLoading(false)
            this.forceUpdate()
            alert('Crumb Claimed!')
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }




    return (
        <div className='honk-wrapper'>
            <div className='honk-instructions'>
                <div>
                    <div className='headone'>
                        Claim your <strong style={{ color: '#dd67ff' }}> rewards! </strong>
                    </div>
                </div>
            </div>
            <br/> <br/>
            <div className="headtwo" style={{marginBottom: "2vh"}}>
                Claimable Items
            </div>
            <Grid container rowSpacing={{xs: 2, sm: 4, md: 6}} columnSpacing={{ xs: 2, sm: 4, md: 6 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                {rewards.map((reward) => (
                    <Grid item xs={2} sm={4} md={4} key={reward.id}>
                        <Card sx={{backgroundColor: "black"}}>
                            <CardActionArea
                                style={{background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(51,51,51,1) 100%)"}}
                            >
                                {loading ?
                                    <CardMedia
                                        component="img"
                                        height="auto"
                                        image="https://pond.b-cdn.net/Goot%20Mission%20Loading.gif"
                                        alt="reward"
                                        sx={{borderRadius: "20px 20px 20px 20px"}}
                                    />:
                                    <CardMedia
                                        component="img"
                                        height="auto"
                                        image={reward.imageUrl}
                                        alt="reward"
                                        sx={{borderRadius: "20px 20px 20px 20px"}}
                                    />}
                            </CardActionArea>
                            <CardActions sx={{backgroundColor:"#333333", borderRadius: "0 0 20px 20px", overflow: 'visible'}} style={{justifyContent: "space-between"}}>
                                    <Typography>
                                        <strong style={{ color: 'white', fontSize: "20px" }}> {reward.name} </strong>
                                        <br/>
                                        <strong style={{ color: '#dd67ff', fontSize: "20px" }}> {reward.amount} x </strong>
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        disabled={reward.disabled}
                                        style={{
                                            borderRadius: 100,
                                            backgroundColor: "#DD67FF",
                                            fontSize: "1.2vw",
                                            fontFamily: "Montserrat Bold",
                                            maxWidth: '500px', maxHeight: '50px', minWidth: '0px', minHeight: '0px'
                                        }}
                                        onClick={async () => await optIn()}
                                    >
                                        Claim
                                    </Button>

                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                {otherRewards.map((reward) => (
                    <Grid item xs={2} sm={4} md={4} key={reward.id}>
                        <Card sx={{backgroundColor: "black"}}>
                            <CardActionArea
                                style={{background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(51,51,51,1) 100%)"}}
                            >
                                {loading ?
                                    <CardMedia
                                        component="img"
                                        height="auto"
                                        image="https://pond.b-cdn.net/Goot%20Mission%20Loading.gif"
                                        alt="reward"
                                        sx={{borderRadius: "20px 20px 20px 20px"}}
                                    /> :
                                    <CardMedia
                                        component="img"
                                        height="auto"
                                        image={`https://www.randswap.com/v1/nft-url/${reward.asssetId}`}
                                        alt="reward"
                                        sx={{borderRadius: "20px 20px 20px 20px"}}
                                    />}
                            </CardActionArea>
                            <CardActions
                                sx={{backgroundColor: "#333333", borderRadius: "0 0 20px 20px", overflow: 'visible'}}
                                style={{justifyContent: "space-between"}}>
                                <Typography>
                                    <strong style={{
                                        color: 'white',
                                        fontSize: "20px"
                                    }}> {reward.assetName ? reward.assetName : "Loading Rewards"} </strong>
                                    <br/>
                                    <strong style={{color: '#dd67ff', fontSize: "20px"}}> {reward.amount} x </strong>
                                </Typography>
                                <Button
                                    variant="contained"
                                    disabled={reward.disabled}
                                    style={{
                                        borderRadius: 100,
                                        backgroundColor: "#DD67FF",
                                        fontSize: "1.2vw",
                                        fontFamily: "Montserrat Bold",
                                        maxWidth: '500px', maxHeight: '50px', minWidth: '0px', minHeight: '0px'
                                    }}
                                    onClick={async () => await optInReward(reward.asssetId, reward.id)}
                                >
                                    Claim
                                </Button>

                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <div className="headtwo" style={{marginBottom: "2vh", marginTop: "2vh"}}>
                Redeemable Items
            </div>
            <Grid container rowSpacing={{xs: 2, sm: 4, md: 6}} columnSpacing={{ xs: 2, sm: 4, md: 6 }} columns={{ xs: 4, sm: 8, md: 12 }} >
            {redeemRewards && redeemRewards.map((reward) => (
                <Grid item xs={2} sm={4} md={4} key={reward.id}>
                    <Card sx={{backgroundColor: "black"}}>
                        <CardActionArea
                            style={{background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(51,51,51,1) 100%)"}}
                        >
                            {loading ?
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image="https://pond.b-cdn.net/Goot%20Mission%20Loading.gif"
                                    alt="reward"
                                    sx={{borderRadius: "20px 20px 20px 20px"}}
                                /> :
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image={`https://www.randswap.com/v1/nft-url/${reward.asssetId}`}
                                    alt="reward"
                                    sx={{borderRadius: "20px 20px 20px 20px"}}
                                />}
                        </CardActionArea>
                        <CardActions
                            sx={{backgroundColor: "#333333", borderRadius: "0 0 20px 20px", overflow: 'visible'}}
                            style={{justifyContent: "space-between"}}>
                            <Typography>
                                <strong style={{
                                    color: 'white',
                                    fontSize: "20px"
                                }}> {reward.assetName} </strong>
                                <br/>
                            </Typography>
                            <Button
                                variant="contained"
                                disabled={reward.disabled}
                                style={{
                                    borderRadius: 100,
                                    backgroundColor: "#DD67FF",
                                    fontSize: "1.2vw",
                                    fontFamily: "Montserrat Bold",
                                    maxWidth: '500px', maxHeight: '50px', minWidth: '0px', minHeight: '0px'
                                }}
                                onClick={async () => await redeemRewardsTx(reward.asssetId)}
                            >
                                Redeem
                            </Button>

                        </CardActions>
                    </Card>
                </Grid>
            ))}
            </Grid>
        </div>
    )
}