import DesktopHeader from "./components/DesktopHeader/DesktopHeader";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import { useState, useEffect } from "react";

const Ventral = 'CGFS7YKYLKEL2PSFNXMEL5ELTDQWPK2DF3N7KM3OLL6BHIMJRKINO3WNRE';
const Deepnothing = 'VEN2D3YJNIGQFMG65WITQYLPIDFAVMF4OAQET3UDBGODJIJLD5COQI5AQE';

export default function Header(props) {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;


    return (
        isMobile ?
            <MobileHeader
                page={props.page}
                setPage={props.setPage}
                setGootBalance={props.setGootBalance}
                setSelectedWallet={props.setSelectedWallet}
                selectedWallet={props.selectedWallet}
                gootBalance={props.gootBalance}
                setWalletType={props.setWalletType}
                connector={props.connector}
                myAlgoWallet={props.myAlgoWallet}
                walletType={props.walletType}
                Ventral={Ventral}
                Deepnothing={Deepnothing}
                cgfs={props.cgfs}
                setCgfs={props.setCgfs}
                algodClient={props.algodClient}


            />
            :
            <DesktopHeader
                page={props.page}
                setPage={props.setPage}
                setGootBalance={props.setGootBalance}
                setSelectedWallet={props.setSelectedWallet}
                selectedWallet={props.selectedWallet}
                gootBalance={props.gootBalance}
                setWalletType={props.setWalletType}
                connector={props.connector}
                myAlgoWallet={props.myAlgoWallet}
                walletType={props.walletType}
                Ventral={Ventral}
                Deepnothing={Deepnothing}
                cgfs={props.cgfs}
                setCgfs={props.setCgfs}
                algodClient={props.algodClient}

            />
    )
}