import lockIcon from './assets/Lock_Icon.svg'
import './Dashboard.css';
import { useState, useEffect } from 'react';
import raffleDeskTop from './assets/HubImagesWeb.svg';
import marketDeskTop from './assets/HubImagesWeb5.svg';
import shopDesktop from './assets/HubImagesWeb3.svg';
import missionDesktop from './assets/Mission_Card_desktop.svg';
import honkDesktop from './assets/HubImagesWeb2.svg';
import goosemashWeb from './assets/HubImagesWeb1.svg';
import bannerWeb from './assets/bannerweb.svg';
import raffleMobile from './assets/HubImagesMobile.svg';
import marketMobile from './assets/HubImagesMobile5.svg';
import shopMobile from './assets/HubImagesMobile3.svg';
import missionMobile from './assets/Mission_card_mobile_new.svg';
import honkMobile from './assets/HubImagesMobile2.svg';
import goosemashMobile from './assets/HubImagesMobile1.svg';
import bannerMobile from './assets/bannermobile.svg';
import cgrMobile from './assets/CGR_MOBILE.png';
import cgrDesktop from './assets/CGR_DESKTOP.png'



export default function Dashboard(props) {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div
            className='bubble-wrapper'
        >
            <div className='wrapper1'>
                <div
                    className='raffle'
                    onClick={() => props.setPage('raffle')}
                    style={{
                        backgroundImage: `url(${isMobile ? raffleMobile : raffleDeskTop})`
                    }}
                >
                    Raffle<br></br>
                    <div className='subtext'>Buy tickets to win<br></br> amazing <span style={{ color: '#dd67ff' }}>prizes</span> !</div>
                </div>
                <div className='market'
                     onClick={() => {window.open("https://crazygooserobots.com")}}
                    style={{
                        backgroundImage: `url(${isMobile ? cgrMobile : cgrDesktop})`
                    }}
                >
                    Crazy Goose Robots
                </div>
            </div>
            <div className='shop'
                style={{
                    backgroundImage: `url(${isMobile ? honkMobile : shopDesktop})`
                }}
                onClick={() => {
                    if (props.cgfs != null) {
                        window.open('http://crazygooseflock.com/cgf-shop/')
                    } else {
                        alert('CGF not detected')
                    }
                }}
            >
                Shop
                <div className='subtext'>Get yourself <span style={{ color: '#dd67ff' }}>exclusive </span>Crazy Goose Flock <br></br>merch {'->'} Holders only</div>
            </div>
            <div className='wrapper2'>
                <div className='missions'
                    onClick={() => props.setPage('missions')}
                    style={{
                        backgroundImage: `url(${isMobile ? missionMobile : missionDesktop})`
                    }}
                >
                    Missions
                </div>
                <div
                    className='honk'
                    onClick={() => props.setPage('honk')}
                    style={{
                        backgroundImage: `url(${isMobile ? shopMobile : honkDesktop})`
                    }}
                >
                    Honk
                    <div className='subtext'>Contact holders directly through<br></br> their wallet</div>
                </div>
            </div>
            <div className='wrapper2'>
                <div
                    className='goosemash'
                    onClick={() => window.open('https://crazygooseflock.com/goosemash')}
                    style={{
                        backgroundImage: `url(${isMobile ? marketMobile : goosemashWeb})`

                    }}
                >
                    Goosemash
                </div>
                <div
                    className='banner'
                    onClick={() => window.open('https://crazygooseflock.com/banner')}
                    style={{
                        backgroundImage: `url(${isMobile ? bannerMobile : bannerWeb})`

                    }}

                >
                    Banner Builder
                    <div className='subtext'>Show off your flock on twitter or discord</div>
                </div>
            </div>
        </div>
    )
}