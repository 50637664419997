import './peraModal.css'


export default function peraModal(props) {
    return (
        <div id="myModal" className="modal" style={{ display: props.display ? '' : 'none' }}>

            <div className="modal-content">
                <span className="close" onClick={props.setPeraModal(false)}>&times;</span>
                <p>Pera Users Opt in to ASA<br></br> {props.asa}</p>
                <button>
                    Ive Opted in
                </button>
            </div>

        </div>
    )
}