import './styles.css'

export default function ColorPicker(props) {
    return (
        <>
            <div style={{ display: props.display }} className='title'>Backgrounds:</div>
            <div style={{ display: props.display }} className='color-wrapper'>
                {props.colors.map((i, index) =>
                    <div
                        key={index}
                        className='color'
                        style={{
                            background: i,
                            border: props.selectedColor === i ? '3px solid #dd67ff' : 'none'
                        }}
                        onClick={() => props.setColor(i)}
                    />
                )}
            </div>
        </>
    )
}