import { useState } from 'react'
import "../../assets/Montserrat-Bold.ttf"
import CheckBox from './CheckBox';
import './styles.css'
import close from '../../assets/close.png';


export default function GoosePicker(props) {
    const [isDropDown, setDropDown] = useState(false);
    const [selectedGeese, setSelectedGeese] = useState([]);
    // const testgoose = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

    return (
        <div style={{ display: props.display }} className="row-wrap-picker">
            <div className='title'>Your CGFs: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className='selector' style={{ outline: isDropDown ? '3px solid #fff' : 'none' }} >
                <div onClick={() => setDropDown(!isDropDown)}>
                    SELECT  <span className='dropdown-caret'>▼</span>
                </div>
                {isDropDown ?
                    <div className='outer-wrapper'>
                        <div className="dropdown-content">

                            {props.names.length > 0 ?
                                props.names.map((i, index) =>
                                    <CheckBox
                                        key={index}
                                        index={index}
                                        gooseurls={props.gooseurls}
                                        checked={selectedGeese.includes(i) ? true : false}
                                        i={i}
                                        selectedGeese={selectedGeese}
                                        setSelectedGeese={setSelectedGeese}
                                        setGooseUrls={props.setGooseUrls}
                                    />
                                )
                                :
                                <div style={{ padddingLeft: '5px' }}>&nbsp;goose not &nbsp;detected</div>
                            }

                        </div>
                    </div>
                    :
                    null
                }

            </div>

            <div className="remove-wrapper">

                {
                    props.gooseurls.map((g, index) =>
                        <div
                            className='goose-remove'
                            key={index}

                        >
                            <div>
                                {selectedGeese[index]}&nbsp;
                            </div>
                            <img
                                src={close}
                                className="x-icon"
                                onClick={() => {
                                    setSelectedGeese(selectedGeese.filter(function (goose) { return goose !== selectedGeese[index] }))
                                    if (props.gooseurls.length === 1) {
                                        props.setGooseUrls([])
                                    } else {
                                        props.setGooseUrls(props.gooseurls.filter(function (goose) { return goose !== g }));
                                    }
                                }}
                                alt="X"
                            />
                        </div>
                    )
                }
            </div>


        </div >
    )
}