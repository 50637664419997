import Mission from "./components/Mission"
import DuoMission from "./components/DuoKissBitsMission";
import DuoMissioNew from "./components/DuiHoiPolyMission"
import {useEffect, useState} from "react";
import {Avatar, Box, Button, Modal, Typography} from "@mui/material";
export default function Missions(props) {
    const [missions, setMissions] = useState([])
    const [rewardsModal, setRewardsModal] = useState(false)
    const missionsList = [
        {
            id: 1,
            name: 'Robin Goot',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'ARROW TRAIT',
            color: '#503621',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions/Mission_Arrow.svg',
            backgroundMobile: 'https://pond.b-cdn.net/mobile/mobile_missions_arrow.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },
        {
            id: 2,
            name: 'Atomic Assembly',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'ATOMIC BODY',
            color: '#b2fe32',
            reward: 75,
            background: 'https://pond.b-cdn.net/missions/atomic.svg',
            backgroundMobile: 'https://pond.b-cdn.net/mobile/mobile_missions_atomic.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },
        {
            id: 3,
            name: 'Kick Off',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'JERSEY TRAIT',
            color: '#009245',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions/Missions_Ultra.svg',
            backgroundMobile: 'https://pond.b-cdn.net/mobile/mobile_missions_ultra.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        }, {
            id: 4,
            name: 'Finance Flock Bros',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'WHITE BODY',
            color: '#d3d3d3',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions/Mission_Boxes_desktop%20(1).svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions/mobile_missions_finance%20(1).svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },
        {
            id: 5,
            name: 'Fishing For Plastic',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'Fishing Bucket / Six-Pack Rings',
            color: '#d3d3d3',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Web_Fishing_For_Plastic.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Mobile_Fishing_For_Plastic.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        }
        , {
            id: 6,
            name: 'Bananarama',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'BANANA TRAIT',
            color: '#F8EB00',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Web_Bananarama.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Mobile_Bananarama.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        }, {
            id: 7,
            name: 'Unblock The Pipe',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'PLUNGER TRIAT',
            color: '#B04930',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Web_Unblock_The_Pipe.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Mobile_Unblock_The_Pipe.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },
        {
            id: 8,
            name: 'Golden Goot Rush',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'GOLD BODY',
            color: '#D3A408',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Web_Gold_Rush.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_2/Missions_2_SVG_Mobile_Gold_Rush.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },
        {
            id: 9,
            name: '$ad Goo$e Club',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: '$AD GOOSE TRAIT',
            color: '#D3D3D3',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_3/Missions_Dekstop_Sad_Goose.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_3/Missions_Mobile_Sad_Goose.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        }
        , {
            id: 10,
            name: 'Awkward Silence',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'AWKWARD TRAIT',
            color: '#FFDFC2',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_3/Missions_Dekstop_Awkward.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_3/Missions_Mobile_Awkward.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        }, {
            id: 11,
            name: 'Stay Home',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'ELECTRONIC TAGGING',
            color: '#F95353',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_3/Missions_Dekstop_Stay_Home.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_3/Missions_Mobile_Stay_Home.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },
        {
            id: 12,
            name: 'Leave No Pin Standing',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'BOWLING TRAIT',
            color: '#rgb(115,127,244)',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_3/Missions_Dekstop_Bowling.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_3/Missions_Mobile_Bowling.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },

        {
            id: 13,
            name: 'Go To Sea',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'SEAMAN TRAIT',
            color: '#2E3192',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Web_Pink_Party.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Mobile_Seaman.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },

        {
            id: 14,
            name: 'Pink Party',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'PINK TRAIT',
            color: '#FF8F71',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Web_Prison.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Mobile_Pink_Party.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },

        {
            id: 15,
            name: 'Inmates For Life',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'PRISON TRAIT',
            color: '#F7931E',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Web_Dart.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Mobile_Prison.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },

        {
            id: 16,
            name: 'Time To Sleep',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'TRANQUILIZER DART',
            color: '#E40F0B',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Web_Seaman.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_4/Missions_4_SVG_Mobile_Dart.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },

        {
            id: 17,
            name: 'Goot 2030',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'PROJECTOR TRAIT',
            color: '#CCCCCC',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_5/Missions_5_SVG_Web_Projector.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_5/Missions_5_SVG_Mobile_Projector.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },

        {
            id: 18,
            name: 'Flock Fest',
            type: 'SOLO',
            duration: '14 DAYS',
            trait: 'FREE FOR ALL',
            color: '#0171BB',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_5/Missions_5_SVG_Web_Free_For_All.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_5/Missions_5_SVG_Mobile_Free_For_All.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },

        {
            id: 19,
            name: 'Goot Paradise',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'HALO',
            color: '#FFEE4D',
            reward: 50,
            background: 'https://pond.b-cdn.net/missions_5/Missions_5_SVG_Web_Halo.svg',
            backgroundMobile: 'https://pond.b-cdn.net/missions_5/Missions_5_SVG_Mobie_Halo.svg',
            rewardsMobile: 'https://pond.b-cdn.net/rewards/Mission_Rewards2_Zeichenflache_1_Kopie.png',
            rewardsDesktop: 'https://pond.b-cdn.net/rewards/Mission_Rewards_Zeichenflache_1.png'
        },
        {
            id: 20,
            name: 'Snows Nights',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'FREE FOR ALL',
            color: '#0171BB',
            reward: 50,
            background: 'https://pond.b-cdn.net/christmas/Missions_6_SVG_Web_Snowy_Pond.svg',
            backgroundMobile: 'https://pond.b-cdn.net/christmas/Missions_6_SVG_Mobile_Snowy_Pond.svg',
            rewardsMobile: 'https://pond.b-cdn.net/christmas/Mission_Rewards_Mobile.png',
            rewardsDesktop: 'https://pond.b-cdn.net/christmas/Mission_Rewards_Christmas_Web.png'
        },
        {
            id: 21,
            name: 'Ice Skating',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'FREE FOR ALL',
            color: '#01B5FF',
            reward: 50,
            backgroundMobile: 'https://pond.b-cdn.net/christmas/Missions_6_SVG_Mobile_Ice_Skating.svg',
            background: 'https://pond.b-cdn.net/christmas/Missions_6_SVG_Web_Ice_Skating.svg',
            rewardsMobile: 'https://pond.b-cdn.net/christmas/Mission_Rewards_Mobile.png',
            rewardsDesktop: 'https://pond.b-cdn.net/christmas/Mission_Rewards_Christmas_Web.png'
        },
        {
            id: 53,
            name: 'Got any more of them $CRUMB?',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'RABIES TRAIT',
            color: '#765E3F',
            reward: 50,
            backgroundMobile: 'https://pond.b-cdn.net/missions-10/Missions_6_Mobile_Rabies.svg',
            background: 'https://pond.b-cdn.net/missions-10/Missions_6_Web_Rabies.svg',
            rewardsMobile: 'https://pond.b-cdn.net/missions-10/Mission_Rewards_Mobile.png',
            rewardsDesktop: 'https://pond.b-cdn.net/missions-10/Mission_Rewards_Web.png'
        },
        {
            id: 54,
            name: 'Goot-Man',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'VILLAN TRIAT',
            color: '#502522',
            reward: 50,
            backgroundMobile: 'https://pond.b-cdn.net/missions-10/Missions_6_Mobile_Goot-Man.svg',
            background: 'https://pond.b-cdn.net/missions-10/Missions_6_Web_Goot-Man.svg',
            rewardsMobile: 'https://pond.b-cdn.net/missions-10/Mission_Rewards_Mobile.png',
            rewardsDesktop: 'https://pond.b-cdn.net/missions-10/Mission_Rewards_Web.png'
        },
        {
            id: 55,
            name: 'Hang Loose',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'FREE FOR ALL',
            color: '#F8AF92',
            reward: 50,
            background: 'https://pond.b-cdn.net/solo/Mission_7_Hang_Loose.svg',
            backgroundMobile: 'https://pond.b-cdn.net/solo/Mission_7_Hang_Loose_Web.svg',
            rewardsMobile: 'https://pond.b-cdn.net/missions-10/Mission_Rewards_Mobile.png',
            rewardsDesktop: 'https://pond.b-cdn.net/missions-10/Mission_Rewards_Web.png'
        },
        {
            id: 56,
            name: 'Honk-O-Meter',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'FREE FOR ALL',
            color: '#ABDAC6',
            reward: 50,
            background: 'https://pond.b-cdn.net/honk/Missions_8.png',
            backgroundMobile: 'https://pond.b-cdn.net/honk/Missions_8_Mobile.png',
            rewardsMobile: 'https://pond.b-cdn.net/honk/Mission_Rewards8_Mobile.png',
            rewardsDesktop: 'https://pond.b-cdn.net/honk/Mission_Rewards8_Web.png'
        },
        {
            id: 58,
            name: 'Comfort Eating',
            type: 'SOLO',
            duration: '7 DAYS',
            trait: 'FREE FOR ALL',
            color: '#F6881B',
            reward: 50,
            background: 'https://pond.b-cdn.net/comfort/Web_Comfort.svg',
            backgroundMobile: 'https://pond.b-cdn.net/comfort/Mobile_Comfort.svg',
            rewardsMobile: 'https://pond.b-cdn.net/comfort/Mission_Rewards_Comfort_Mobile.png',
            rewardsDesktop: 'https://pond.b-cdn.net/comfort/Mission_Rewards_Comfort_Web.png'
        },
    ]
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        bgcolor: '#323332',
        border: '5px solid #dd67ff',
        boxShadow: 24,
        p: 4,
        color: 'white',
        borderRadius: '16px',
    };

    useEffect(() => {
        if (props.selectedWallet === null) {
            alert('Connect your wallet')
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/missions/${props.selectedWallet}`)
                .then(res => res.json())
                .then(eligibleGoots => {

                    missionsList[24].goots = eligibleGoots[24].eligbleGoots
                    missionsList[25].goots = eligibleGoots[26].eligbleGoots
                    missionsList.shift()
                    missionsList.shift()
                    missionsList.shift()
                    missionsList.shift()
                    const newMissionList = []
                    const skipNumber = missionsList.length - 2
                    for (const mission in missionsList) {
                        if (mission >= skipNumber) {
                            newMissionList.push(missionsList[mission])
                        } else {
                            for (const goot in missionsList[mission].goots) {
                                if (missionsList[mission].goots[goot].staked === true) {
                                    newMissionList.push(missionsList[mission])
                                }
                            }
                        }
                    }
                    setMissions(newMissionList.reverse())
                })
        }
    }, [props.selectedWallet])


    return (
        <div className='honk-wrapper'>
            <div className='honk-instructions'>
                <div>
                    <h1 className='headone'>Missions</h1>
                    <br>
                    </br>
                    <div className='honk-text'>
                        Let your goot Embark on different <strong style={{color: '#dd67ff'}}> missions</strong> and win
                        various prizes! Maybe your CGF will be the next hero
                        in the flock, score the decisive goal for Crazy Goose F.C
                        or will you protect the good princess from the
                        treacherous foxes...who knows, but we're here to find out!

                        To win the prizes the CGF <strong style={{color: '#dd67ff'}}> can't leave</strong> the wallet
                        for the
                        duration of the mission. If they are moved or listed you'll
                        not be eligible for rewards!
                    </div>
                    <br></br>
                    <br></br>
                </div>
                {window.innerWidth <= 768 ? null : <img src="mission_image.svg" className="mission-image"/>}
            </div>
            <div className='honk-text' style={{cursor: 'pointer'}} onClick={() => {
                window.open('https://ventral.gitbook.io/crazy-goose-flock/utility/missions')
            }}>
                <img src="https://pond.b-cdn.net/missions/i%20(1).svg"
                     style={{height: "35px", verticalAlign: "middle"}}/>
                How To Missions
            </div>
            <div>
                {window.innerWidth <= 768 ?
                    <div className='honk-text' style={{marginTop: "0", fontSize: "20px", float: "left"}}>

                    </div>
                    :
                    <div className='honk-text' style={{marginTop: "5vh", fontSize: "50px", float: "left"}}>

                    </div>
                }

                <div style={{float: "right", marginTop: "1vh", marginBottom: "1vh"}}>
                    {window.innerWidth <= 768 ?
                        <Button
                            variant="contained"
                            style={{
                                borderRadius: 100,
                                backgroundColor: "#e2b006",
                                fontSize: "2.5vw",
                                fontFamily: "Montserrat Bold",
                                maxWidth: '500px', maxHeight: '200px', minWidth: '0px', minHeight: '0px',
                            }}
                            startIcon={
                                <Avatar
                                    style={{
                                        maxWidth: '150px', maxHeight: '25px', minWidth: '60px', minHeight: '25px',
                                        overflow: "visible"
                                    }}
                                    src={
                                        "https://pond.b-cdn.net/Goot_Loot.svg"
                                    }
                                />
                            }
                            onClick={() => props.setPage("rewards")}
                        >
                            Redeem Rewards
                        </Button>
                        :
                        <Button
                            variant="contained"
                            style={{
                                borderRadius: 100,
                                backgroundColor: "#e2b006",
                                fontSize: "1.2vw",
                                fontFamily: "Montserrat Bold",
                                maxWidth: '500px', maxHeight: '300px', minWidth: '0px', minHeight: '0px'
                            }}
                            startIcon={
                                <Avatar
                                    style={{
                                        maxWidth: '150px', maxHeight: '150px', minWidth: '50px', minHeight: '50px',
                                        overflow: "visible"
                                    }}
                                    src={
                                        "https://pond.b-cdn.net/Goot_Loot.svg"
                                    }
                                />
                            }
                            onClick={() => props.setPage("rewards")}
                        >
                            Redeem Rewards
                        </Button>
                    }

                </div>
            </div>

            <img src="/Mission_Message.png" />


        </div>
    )
}